:root {
  --primary: 63 80 181;
  --primary-contrast: 249 250 251;

  --text: theme("colors.gray.950");
  --text-secondary: 107 107 107;

  --background: #ffffff;
  --surface: theme("colors.gray.100");

  --border: 196 196 196;

  --disabled: 220 220 221;

  --positive: #66bb6a;
  --negative: #f44336;

  @media (prefers-color-scheme: dark) {
    --background: 18 18 18;
    --surface: 31 31 31;
    --surface-top: 25 25 25;

    --text: theme("colors.gray.100");
    --text-secondary: 188 188 188;

    --tag: 53 60 69;

    --border: 73 73 73;

    --disabled: 53 60 69;
  }
}
