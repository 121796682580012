.react-datepicker.datepicker-default {
  @apply w-full;
  @apply bg-background bg-noise border-2 border-surface rounded-sm overflow-hidden;
  @apply text-inherit;

  & * {
    @apply text-inherit;
  }

  & .react-datepicker__month-container {
    @apply w-full;

    & .react-datepicker__header {
      @apply w-full;
      @apply bg-surface text-text;
      @apply border-none rounded-none;

      & .react-datepicker__current-month,
      & .react-datepicker__day-name {
        @apply text-text;
      }

      & .react-datepicker__day-name {
        @apply font-semibold;
      }
    }

    & .react-datepicker__month {
      @apply w-full;
      @apply p-4 m-0;
    }

    & .react-datepicker__day {
      @apply font-light;
      @apply transition-colors duration-300;
      @apply rounded-sm m-1;

      &.react-datepicker__day--selected,
      &:active {
        @apply bg-primary text-primaryContrast font-semibold shadow-[0_0_0_4px] shadow-primary/50;
      }

      &:hover {
        @apply bg-primary;
      }
    }
  }
}

.datepicker-time.react-datepicker-popper {
  .react-datepicker__triangle {
    @apply text-surface fill-surface stroke-border;
  }
  .react-datepicker {
    @apply text-inherit bg-surface border border-border overflow-hidden rounded-sm;
    & * {
      @apply text-inherit;
    }

    .react-datepicker__time-container {
      @apply w-auto border-none;

      .react-datepicker__header {
        @apply bg-surface border-none;
        @apply rounded-none p-2;

        .react-datepicker-time__header {
        }
      }

      .react-datepicker__time {
        @apply bg-surface;

        .react-datepicker__time-list {
          @apply scrollbar-thin;

          .react-datepicker__time-list-item {
            @apply h-auto w-auto p-2 rounded-sm transition-colors duration-300;
            &:hover {
              @apply bg-tag;
            }
          }
        }
      }
    }
  }
}
