:root {
  --toastify-color-light: theme("colors.surface-top");
  --toastify-color-dark: theme("colors.surface-top");
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: auto;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: inherit;
  --toastify-z-index: 9999;

  --toastify-toast-bd-radius: theme("borderRadius.sm");

  --toastify-text-color-light: theme("colors.text");
  --toastify-text-color-dark: theme("colors.text");

  // Used only for colored theme
  --toastify-text-color-info: theme("colors.text");
  --toastify-text-color-success: theme("colors.text");
  --toastify-text-color-warning: theme("colors.text");
  --toastify-text-color-error: theme("colors.text");

  --toastify-spinner-color: theme("colors.text");
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  --toastify-color-progress-light: theme("colors.primary");
  --toastify-color-progress-dark: theme("colors.primary");
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  // used to control the opacity of the progress trail
  --toastify-color-progress-light: theme("colors.primary");
  --toastify-color-progress-bgo: 0.2;
}

.Toastify__toast-container {
  @apply text-text text-sm p-2;
}

.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  @apply p-2 flex items-center gap-4 mb-2 rounded-sm;
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  @apply p-0 m-0 flex-1;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notification color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
}

.Toastify__progress-bar--wrp {
  @apply rounded-none h-px;
  & .Toastify__progress-bar--bg {
  }
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  @apply self-auto text-text stroke-1;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
  @apply stroke-1;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}
