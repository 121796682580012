input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="time"],
select {
  @apply min-w-0 max-w-full;
}

textarea {
  @apply bg-transparent border border-border rounded-sm;
  @apply p-2;
  @apply text-sm;
  @apply focus:ring-0 focus:border-border;
  @apply disabled:text-border;
}

select {
  @apply bg-transparent border-border px-2 flex-1 w-full;
  @apply focus:outline-none focus:ring-0 focus:border-border;
  @apply text-sm;
  @apply disabled:text-text-secondary;
}

/* input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="time"],
select {
  @apply bg-transparent border border-border rounded-sm w-full;
  @apply px-2 py-2;
  @apply focus:outline-none focus:ring-0 focus:border-border;
  @apply sm:text-sm;
  @apply disabled:text-border;
}

.input-merged {
  @apply flex items-stretch;

  & input {
    @apply rounded-r-none border-r-0;
  }

  & button {
    @apply border border-border last:border-l-0 rounded-none last:rounded-r-sm;
    @apply px-3;
    @apply bg-primary text-primaryContrast;
    @apply [&:not(:disabled)]:hover:opacity-70 transition-opacity duration-300 disabled:bg-disabled;
  }
} */
