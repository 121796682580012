.color-picker {
  @apply flex flex-col gap-4;
  @apply bg-background p-2 rounded-sm;

  .react-colorful {
    @apply w-full bg-background;
    @apply flex flex-col gap-4;
  }

  .react-colorful__saturation {
    @apply rounded-sm cursor-pointer;
  }

  .react-colorful__saturation-pointer {
    @apply shadow-[0_0_4px_rgba(0,0,0,0.4)] rounded-sm border;
  }

  .react-colorful__hue {
    @apply rounded-sm h-2 cursor-pointer;
  }

  .react-colorful__hue-pointer {
    @apply shadow-[0_0_4px_rgba(0,0,0,0.4)] border rounded-sm;
    @apply h-3 w-2;
  }

  .color-picker-swatches {
    @apply flex gap-2;

    .color-picker-swatch {
      @apply transition-shadow duration-300 hover:shadow-[0_0_0_1px_var(--swatch-color),inset_0_0_0_10px_var(--swatch-color)];
      @apply w-5 h-5 rounded-sm;
    }
  }
}