@tailwind base;
@tailwind components;
@tailwind utilities;

@import "colors";
@import "loader";
@import "datepicker";
@import "colorpicker";
@import "input";
@import "fonts";
@import "toast";

:root {
  --font-OpenSans: "OpenSans", sans-serif;
  --font-Montserrat: "Montserrat", sans-serif;
  --font-Fredoka: "Fredoka", sans-serif;
  --font-Poppins: "Poppins", sans-serif;
  --font: var(--font-OpenSans);
}

html,
body {
  @apply min-h-full;
}

html,
body,
#root {
  @apply min-h-full;
  @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-border scrollbar-thumb-rounded-none scrollbar-corner-transparent;
}

body {
  font-family: var(--font);
  @apply bg-background text-text;
  @apply bg-gradient-to-b from-background to-primary/10 relative;
  //@apply after:content-[""] after:-z-[1] after:bg-noise after:fixed after:left-0 after:top-0 after:right-0 after:bottom-0;
}

.markdown {
  h1, h2, h3, h4, h5, h6{
    @apply font-semibold mb-1 mt-3;
  }
  h2 {
    @apply text-base;
  }
  h3 {
    @apply text-lg;
  }
  h4 {
    @apply text-xl;
  }
  p {
    @apply mb-2;
  }
  a {
    @apply text-primary underline;
  }
}

hr {
  @apply w-full h-px;
  @apply bg-white/10 my-2 border-none;
}

.cutout {
  @apply absolute left-0 top-0 right-0 bottom-0 overflow-hidden w-full h-full block;
}
.cutout:before {
  @apply content-[""];
  @apply absolute inset-0;
  @apply bg-[radial-gradient(circle_at_50%_0,transparent_50%,_theme("colors.background")_50%)];
  @apply w-full h-full;
}
