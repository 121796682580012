$path: "/fonts/";
$fonts: "OpenSans", "Montserrat", "Fredoka", "Poppins";
$weights: (
  "Light": 300,
  "Regular": 400,
  "Medium": 500,
  "SemiBold": 600,
  "Bold": 700,
);

@each $font in $fonts {
  @each $name, $weight in $weights {
    @font-face {
      font-family: $font;
      src: url(#{$path}#{$font}/#{$font}-#{$name}.ttf) format("truetype");
      font-display: swap;
      font-weight: $weight;
    }
  }
}
